import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';

import { LoanAssignmentComponent } from '@wlms-web/features/loans';
import { WarehouselinedetailsComponent, WarehouselineDetailGridviewComponent } from '@wlms-web/features/warehouse-lines';
import { ExportHistoryComponent } from '@wlms-web/features/export-history';

// import { AuthGuard, DeactivateGuard } from '@wlms-web/data-access';
import { ScreenActionPrivileges } from '@wlms-web/utils';
import { AppComponent } from './app.component';

const routes: Routes = [
  // Routes without master-page or that do not need to be authenticated need to go first
  {
    path: '',
    component: AppComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'loan-assignment',
    component: LoanAssignmentComponent,
    canActivate: [MsalGuard],
    data: { privilegeKey: ScreenActionPrivileges.LoanAssignment }
  },
  {
    path: 'warehouselines',
    component: WarehouselineDetailGridviewComponent,
    canActivate: [MsalGuard],
    data: { privilegeKey: ScreenActionPrivileges.Warehouseline }
  },
  {
    path: 'warehouseline-details',
    component: WarehouselinedetailsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'export-history',
    component: ExportHistoryComponent,
    canActivate: [MsalGuard],
    data: { privilegeKey: ScreenActionPrivileges.ExportHistory }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }