import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { BrowserStorageService, StorageKey, ConfigKey, ConfigService, SharedService, ScreenActionPrivileges } from '@wlms-web/utils'
import { UiHelperService } from '@wlms-web/ui-kit';
import { MsalService } from '@azure/msal-angular';
import { AuthUtils } from 'libs/utils/src/lib/auth/auth-utils';

@Component({
  selector: 'wlms-web-sliding-menu',
  templateUrl: './sliding-menu.component.html',
  styleUrls: ['./sliding-menu.component.scss'],
})
export class SlidingMenuComponent implements OnInit {
  searchPanel = false;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  userDetails: any;
  isAuthorised = false;
  screenActionPrivileges = ScreenActionPrivileges;

  constructor(private router: Router,
    private browserStorageService: BrowserStorageService,
    private sharedService: SharedService,
    private appConfigService: ConfigService,
    private uiHelperService: UiHelperService,
    private authService: MsalService,
    public authUtils: AuthUtils,
    ) { }

  ngOnInit(): void {
    this.userDetails = this.authUtils.getLoggedInUserDetails();
  }

  loadPage(page) {
    switch (page) {
      case 'warehouseLineList':
        this.router.navigateByUrl('/warehouselines');
        break;
      case 'loanAssignment':
        this.router.navigateByUrl('/loan-assignment');
        break;
        case 'exporthistory':
          this.router.navigateByUrl('/export-history');
          break;
    }
  }
}
