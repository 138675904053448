export enum ScreenActionPrivileges {
  // Menu items
  Search = 'Search',
  AllowTestLoanToggle = 'AllowTestLoanToggle',
  LoanAssignment = 'LoanAssignment',
  Warehouseline = 'Warehouseline',
  AddExceptions = 'Exceptions',
  // Loan assignment action dropdown
  Assign = 'Assign',
  MarkReview = 'MarkReview',
  AssignandExport = 'AssignandExport',
  PushBackToFunding = 'PushBackToFunding',
  UpdateAdvanceDate = 'UpdateAdvanceDate',
  // Loan assignment icons on the top
  Refresh = 'Refresh',
  LoanSavePreference = 'LoanSavePreference',
  ReProcessLoanUpdate ='ReProcessLoanUpdate',
  // Api Calls
  GetLoanSavePreference = 'GetLoanSavePreference',
  // Grid Actions
  LoanAssignmentRightMouseClick = 'LoanAssignmentRightMouseClick',
  LoanGridAction = 'LoanGridAction',
  WarehouselineRightMouseClick = 'WarehouselineRightMouseClick',
  WarehouselineGridAction = 'WarehouselineGridAction',
  Exceptions = 'Exceptions',

  EditWarehouseline = 'EditWarehouseline',
  WarehouselineSavePreference = 'WarehouselineSavePreference',
  GetWarehouseLineSavePreference = 'GetWarehouseLineSavePreference',
  EditDailyCabTab = 'EditDailyCabTab',
  EditTimeAvailabilityTab = 'EditTimeAvailabilityTab',
  EditWetOverRidesTab = 'EditWetOverRidesTab',
  GetTimeAvailabilityTabSavePreference = 'GetTimeAvailabilityTabSavePreference',
  GetWetOverRidesTabSavePreference = 'GetWetOverRidesTabSavePreference',
  TimeAvailabilityTabGridAction = 'TimeAvailabilityTabGridAction',
  TimeAvailabilityTabRightMouseClick = 'TimeAvailabilityTabRightMouseClick',
  TimeAvailabilityTabSavePreference = 'TimeAvailabilityTabSavePreference',
  TimeDailyCabTabGridAction = 'TimeDailyCabTabGridAction',
  TimeDailyCabTabRightMouseClick = 'TimeDailyCabTabRightMouseClick',
  WetOverRidesTabGridAction = 'WetOverRidesTabGridAction',
  WetOverRidesTabRightMouseClick = 'WetOverRidesTabRightMouseClick',
  WetOverRidesTabSavePreference = 'WetOverRidesTabSavePreference',
  DailyCabTab = 'DailyCabTab',
  TimeAvailabilityTab = 'TimeAvailabilityTab',
  WetOverRidesTab = 'WetOverRidesTab',
  TrancheTab = 'TrancheTab',
  EditTrancheTab = 'EditTrancheTab',
  TrancheTabRightMouseClick = 'TrancheTabRightMouseClick',
  TrancheTabGridAction = 'TrancheTabGridAction',
  CopyContract = 'CopyContract',
  CopyTranche = 'CopyTranche',
  TrancheTabSavePreference = 'TrancheTabSavePreference',
  DailyCapsTabSavePreference = 'DailyCapsTabSavePreference',
  GetDailyCapsTabSavePreference = 'GetDailyCabTabSavePreference',
  GetTrancheTabSavePreference = 'GetTrancheTabSavePreference',
  WarehouselineTile ='WarehouselineTile',
  LoanQueueTile ='LoanQueueTile',
  ExportHistory ='ExportHistory',
  ExportHistorySavePreference ='ExportHistorySavePreference',
  GetExportHistorySavePreference ='GetExportHistorySavePreference',
  ExportHistoryRightMouseClick ='ExportHistoryRightMouseClick',
  ExportHistoryGridAction ='ExportHistoryGridAction'
}

export const Icon = {
  email: 'icon-mail',
  dob: 'icon-birthday',
  user: 'icon-user',
  call: 'icon-call',
  location: 'icon-map-pin',
  phone: 'icon-phone',
  messaging: 'icon-messaging',
  mobile: 'icon-mobile',
  singleUser: 'icon-single-user',
  certificate: 'icon-certificate',
  message: 'icon-message',
  tick: 'icon-tick_icon',
  pushRightArrow: 'icon-vectorpaint',
  pushLeftArrow: 'icon-icons-_-_-arrows-_-chevrons-left',
  thumbsUp: 'icon-icons-_-thumbs-up',
  trash: 'icon-icons-_-trash-2',
  refresh: 'icon-refreshing',
};

export const LoanDetailsPanelTabs = [
  { id: 1, icon: 'icon-info', name: 'info' }
]

export const LoanDetailCategories = [
  { categoryId: 1, name: 'Loan' },
  { categoryId: 2, name: 'Borrower' },
  { categoryId: 3, name: 'Key Dates' }
]

export const WarehouseLineDetails = [
  {
    name: 'Summary',
    code: 'Summary'
  },
  {
    name: 'Tranche',
    code: 'Tranche',
    privilegeKey: ScreenActionPrivileges.TrancheTab,
    suppressRightClickPrivilege: ScreenActionPrivileges.TrancheTabRightMouseClick,
    suppressActionPrivilege: ScreenActionPrivileges.TrancheTabGridAction,
  },
  {
    name: 'Account',
    code: 'Account'
  },
  {
    name: 'Limits',
    code: 'Limits'
  },
  {
    name: 'Investors',
    code: 'Investors'
  },
  {
    name: 'Products',
    code: 'Products'
  },
  {
    name: 'Eligibility',
    code: 'Eligibility'
  },
  {
    name: 'Sweeps',
    code: 'Sweeps'
  }]

export class NoRowsOverlayFlags {
  static readonly ISLOANASSIGNMENTS = 'isLoanAssignments';
  static readonly WAREHOUSES = 'warehouses';
  static readonly EXCEPTIONS = 'exceptions';
  static readonly TRANCHES = 'tranches';
  static readonly NORECORDS = 'noRecords';
}

export const LoanDetailTileList = [
  { label: 'Line Limit', value: 0, icon: null, remainingPercentage: null, excludeInTabs: [] },
  { label: 'Outstanding', value: 0, icon: null, remainingPercentage: null, excludeInTabs: ['Unassigned'] },
  { label: 'Available', value: 0, icon: null, remainingPercentage: null, excludeInTabs: [] },
  { label: 'Wire Amount Total', value: 0, icon: null, remainingPercentage: null, excludeInTabs: ['All'] }
];

export const LoanAssignmentLoadingTemplate =
  '<span class="ag-overlay-loading-center">Loading...</span>';

export const WarehouseException = [
  { name: 'Warehouse Lines', privilegeKey: ScreenActionPrivileges.Warehouseline },
  { name: 'Exceptions', privilegeKey: ScreenActionPrivileges.Exceptions }
];
export class ApiConfig {
  // When DISABLECACHE is set to true and passed as second argument in get api call
  // then the api will be not be cached and if set false it will be cached
  static readonly DISABLESEARCHLOANCACHE = true;
  static readonly DISABLELOANCACHE = true;
  static readonly DISABLEGETTIMECACHE = true;

}

export class ValidationConfig {
  static readonly AlphaNumeric = '^[A-Za-z0-9]*$';
  static readonly AllowOnlyNumeric = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  static readonly LoanNumberFieldLength = '10';
}

export const LoanAssignmentActions = [{
  internalName: 'Assign', name: 'Assign', excludeInTabs: [],
  privilegeKey: ScreenActionPrivileges.Assign
},
{
  internalName: 'AssignAndExport', name: 'Export', excludeInTabs: ['Unassigned', 'All'],
  privilegeKey: ScreenActionPrivileges.AssignandExport
},
{ internalName: 'MarkReviewed', name: 'Mark Reviewed', excludeInTabs: [], privilegeKey: ScreenActionPrivileges.MarkReview },
{
  internalName: 'PushBackFundingQueue', name: 'Push Back To Funding Queue', excludeInTabs: [],
  privilegeKey: ScreenActionPrivileges.PushBackToFunding
},
{
  internalName: 'UpdateAdvanceDate', name: 'Update Advance Date', excludeInTabs: [],
  privilegeKey: ScreenActionPrivileges.AssignandExport
}];

export class SnackbarConfig {
  static readonly horizontalPosition = 'center';
  static readonly verticalPosition = 'top';
  static readonly duration = 10000;
  static readonly error = 'red-snackbar';
  static readonly success = 'success-snackbar';
  static readonly info = 'info-snackbar';
  static readonly warning = 'warning-snackbar';
}

export class StorageKey {
  static readonly PRIVILEGES = 'privileges';
  static readonly AUTHTOKEN = 'authToken';

  static readonly LOGININFO = 'wlmsLoginDetails';
  static readonly USERSELECTION = 'UserSelection';
  static readonly CONDITIONUSERSELECTION = 'conUserSelection';
  static readonly AZURELOGGEDINUSER = 'azureLoggedInUser';
  static readonly AUTHUSER = 'authuser';
  static readonly FILTERMODEL = 'filtermodel';
}

export const mapUserDetails = function (inputObject: any): any {
  return {
    IsValid: inputObject.IsValid,
    UserGuid: inputObject.UserGuid,
    UserName: inputObject.UserName,
    ErrorMessage: inputObject.ErrorMessage,
    AuthToken: inputObject.AuthToken,
    TokenExpiry: inputObject.TokenExpiry,
    UserProfile: inputObject.UserProfile
  };
};

export class ConfigKey {
  static readonly LOGOUTTENANTIDKEY = 'tenantId';
  static readonly LOGOUTREDIRECTURIKEY = 'redirectUri';
}
export enum ColourCodes {
  ManualWireInstruction = '#FF0000',
  IsReviewed = '#1b961b',
  VarianceWithinThreshold = '#FFFF00 !important',
  Exception = '#ffdae0',
  DoesFurtherCreditExist = '#FFFF00'
}

export enum GridMaster {
  LoanAssignment = 'LoanAssignment',
  Warehouseline = 2,
  TimeAvailability = 3,
  DailyCaps = 4,
  WetOverride = 5,
  Tranche = 6,
  ExportHistory = 'ExportHistory'
}

export class TimeCalculateValue {
  static readonly MILLI_SECONDS_IN_A_SECOND = 1000;
  static readonly HOURS_IN_A_DAY = 24;
  static readonly MINUTES_IN_A_HOUR = 60;
  static readonly SECONDS_IN_A_MINUTE = 60;
}

export class TabConstantValue {
  static readonly ALL = "All";
  static readonly UNASSIGNED = 'UNASSIGNED';
}

export enum NumericEditorValidators {
  WarehouseLineTabValidator = 'WarehouseLineTabValidator',
  TimeAvailablityTabValidator = 'TimeAvailablityTabValidator',
  DailyCapsTabValidator = 'DailyCapsTabValidator',
  WetOverridesTabValidator = 'WetOverridesTabValidator'

}

export const WarehouseLineTabs = [
  {
    name: 'Warehouse Lines', code: 'warehouses',
    privilegeKey: ScreenActionPrivileges.Warehouseline,
    suppressRightClickPrivilege: ScreenActionPrivileges.WarehouselineRightMouseClick,
    suppressActionPrivilege: ScreenActionPrivileges.WarehouselineGridAction,
    numericValidatorType: NumericEditorValidators.WarehouseLineTabValidator
  },
  {
    name: 'Time Availability', code: 'timeAvailability',
    privilegeKey: ScreenActionPrivileges.TimeAvailabilityTab,
    suppressRightClickPrivilege: ScreenActionPrivileges.TimeAvailabilityTabRightMouseClick,
    suppressActionPrivilege: ScreenActionPrivileges.TimeAvailabilityTabGridAction,
    numericValidatorType: NumericEditorValidators.TimeAvailablityTabValidator
  },
  {
    name: 'Daily Caps Availability', code: 'dailycapAvailability',
    privilegeKey: ScreenActionPrivileges.DailyCabTab,
    suppressRightClickPrivilege: ScreenActionPrivileges.TimeDailyCabTabRightMouseClick,
    suppressActionPrivilege: ScreenActionPrivileges.TimeDailyCabTabGridAction,
    numericValidatorType: NumericEditorValidators.DailyCapsTabValidator
  },
  {
    name: 'Wet Overrides', code: 'wetOverrides',
    privilegeKey: ScreenActionPrivileges.WetOverRidesTab,
    suppressRightClickPrivilege: ScreenActionPrivileges.WetOverRidesTabRightMouseClick,
    suppressActionPrivilege: ScreenActionPrivileges.WetOverRidesTabGridAction,
    numericValidatorType: NumericEditorValidators.WetOverridesTabValidator
  }
];

export enum TimeLimits {
  dayStart = '08:15:00',
  timeGap = '15',
  dummyId = '-1'
}

export enum ExportWarehouseName {
  BARC = 'Barclays - Warehouse',
  CS = 'Credit Suisse - Warehouse',

}

export const CapType = [
  { id: 1, name: 'Available Amount' },
  { id: 2, name: 'Line limit Amount' }
]

export enum DriveFrom {
  Limit = 1,
  Amount = 2
}
export const OverrideType = [
  { id: 1, name: '% of Line Limit' },
  { id: 2, name: 'User Set Amount' }
]

export class LoanUpdateStatus {
  static readonly NotStarted = "NotStarted";
  static readonly InProgress = "InProgress";
  static readonly Failed = "Failed";
  static readonly Success = "Success";
  static readonly InProgressWithTreasuryTool = "InProgressWithTreasuryTool";
}
export enum ExportRequestAction{
    /// <summary>
    /// Regular loan assignment export
    /// </summary>
    Export = 1,
    /// <summary>
    /// Re-Export for batch
    /// </summary>
    ReExport = 2,
    /// <summary>
    /// CSV Generator
    /// </summary>
    CsvGenerator = 3
}

export const LOSList = [
  { name: 'Empower'},
  { name: 'EmpowerWeb'},
  { name: 'Heloc'}
];
