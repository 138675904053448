import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { NoRowsOverlayFlags } from '@wlms-web/utils';

@Component({
  selector: 'custom-overlay',
  templateUrl: './overlay.component.html',
})
export class Overlay implements INoRowsOverlayAngularComp {
  public params: any;
  blankTemplateFlag: string;
  message: {
    isLoanAssignments: string,
    noRecords: String,
    tranches: String,
    warehouses:String
  };

  constructor() { }

  agInit(params): void {
    let flagObject: any = {};
    this.blankTemplateFlag = '';
    this.message = {
      isLoanAssignments: NoRowsOverlayFlags.ISLOANASSIGNMENTS,
      noRecords: NoRowsOverlayFlags.NORECORDS,
      tranches: NoRowsOverlayFlags.TRANCHES,
      warehouses:NoRowsOverlayFlags.WAREHOUSES
    };
    this.params = params;
    flagObject = this.params.noRowsMessageFunc();
    this.blankTemplateFlag = flagObject?.blankTemplateFlag;
  }

}
